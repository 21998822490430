<template>
  <div class="container">
    <div class="graytitle">{{ $t("cart.check_title") }}</div>
    <!-- 收货地址 -->
    <div class="address" v-show="!item.aguid">
      <div @click="$router.push('/my/address?url=chichek')" style="cursor: pointer">
        <span class="iconfont icon-dingwei"></span>
        <div class="adDetail" v-if="curAddress">
          <p class="blod name">{{ curAddress.name }}</p>
          <p>{{ curAddress.tel }}，{{ curAddress.email }}</p>
          <p>
            {{ curAddress.province }}{{ curAddress.city }}{{ curAddress.area
            }}{{ curAddress.address }}
            <span v-if="curAddress.zcode">，{{ curAddress.zcode }}</span>
          </p>
        </div>
        <div class="noaddress" v-else>{{ $t("cart.please_address") }}</div>
        <span class="iconfont icon-jiantou1"></span>
      </div>
    </div>
    <div class="cartList">
      <div class="cItem">
        <div class="cimg">
          <q-img :src="item.photo" :ratio="6 / 8" @click="loupeHandle(item)" />
        </div>
        <div class="ctxt">
          <p class="brand blod">{{ item.brand }}</p>
          <p class="name">
            {{ item.name }}
          </p>
          <p class="code">{{ item.code }}</p>
          <p>
            {{ $t("cart.goods_color") }}
            <span class="blod">{{
                item.color
            }}</span>
          </p>
          <p v-if="item.size != '01'">
            {{ $t("cart.goods_size") }}
            <span class="blod">{{ item.size }}</span>
          </p>
          <p>
            {{ $t("cart.nums") }}
            <span class="blod">{{ item.quantity }}</span>
          </p>
          <!-- <p>
            {{ $t("cart.total") }}
            <span class="blod">
              {{item.amountType}}{{ item.amount }}
            </span>
          </p> -->
        </div>
      </div>
      <div class="statistics">
        <div>
          <span>{{ $t("cart.sum") }}</span>
          <span class="blod">{{ item.quantity }}{{ $t("cart.num") }}</span>
        </div>
        <div v-if="item.realamount !== '0.00'">
          <span>{{ $t("cart.paid") }}</span>
          <span class="blod">{{ item.amountType }}{{ item.realamount }}</span>
        </div>
        <div>
          <span>{{ $t("cart.account_pay") }}</span>
          <span class="blod">{{ item.amountType }}{{ item.payableamount }}</span>
        </div>
      </div>
      <div class="btnBox">
        <q-btn size="lg" class="full-width" color="primary" :label="$t('cart.play')" @click="goPay()" />
      </div>
    </div>
  </div>
</template>

<script>
import { address } from '@/api/my'
import { getOrderDetailApi } from '@/api/goods'

export default {
  data () {
    return {
      item: '',
      curAddress: '',
      addressList: []
    }
  },
  methods: {
    async getOrderDetail () {
      const res = await getOrderDetailApi({
        action: 'Details',
        guid: this.$route.query.guid
      })
      if (res.status === 1) {
        this.item = res
        this.item.photo = res.imgurl.split(',')[0]
      } else {
        this.$router.push('/')
      }
    },
    // 获取收货地址
    async getAddress () {
      const res = await address({
        type: 0,
        coupon: 1
      })
      if (res.status === 1) {
        // 获取配送信息
        this.addressList = res.list
        // 获取默认地址做为当前地址
        this.curAddress = res.list.find((item) => {
          return item.defaults === true
        })
        // 没有默认地址则拿第一个地址作为当前地址
        if (this.curAddress === undefined) {
          this.curAddress = res.list[0]
        }
      }
    },
    // 选择地址
    changeAddress (index) {
      this.addressList.forEach((item) => {
        item.defaults = false
      })
      this.addressList[index].defaults = true
      this.curAddress = this.addressList[index]
    },
    // 下单
    goPay () {
      if (this.curAddress === '' || this.curAddress === undefined) {
        this.$q.notify(this.$t('cart.enter_address'))
        return
      }
      this.$router.push({
        path: '/payment',
        query: {
          price: this.item.payableamount,
          mark: this.$route.query.guid,
          aguid: this.curAddress.guid,
          isSystem: 1
        }
      })
    },
    loupeHandle (item) {
      this.$viewerApi({
        options: {
          transition: false
        },
        images: item.imgurl.split(',')
      })
    }
  },
  mounted () {
    this.getAddress()
    this.getOrderDetail()
  }
}
</script>

<style lang="scss" scoped>
.graytitle {
  width: 100%;
  height: 41px;
  line-height: 41px;
  text-align: center;
  font-weight: bold;
  background-color: #e5e5e5;
}

.address {
  min-height: 146px;
  border-bottom: 29px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .noaddress {
    display: flex;
    align-items: center;
  }

  &>div {
    width: 930px;
    margin: 0 auto;
    display: flex;
    position: relative;

    .icon-dingwei {
      font-size: 25px;
    }

    .adDetail {
      margin-left: 10px;

      p {
        margin: 0;
      }

      .name {
        margin-bottom: 10px;
      }
    }

    .icon-jiantou1 {
      position: absolute;
      right: 0px;
      top: 50%;
      font-size: 30px;
      margin-top: -15px;
    }
  }
}

.cartList {
  max-width: 978px;
  margin: 0 auto;
  padding: 0 10px;

  .cItem {
    border-bottom: 1px solid #cbcbcb;
    display: flex;
    align-items: center;
    padding: 10px 0;
    position: relative;

    .cimg {
      margin-right: 20px;
      width: 125px;
      min-width: 125px;
      cursor: pointer;
    }

    .ctxt {
      p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }

      .code {
        margin-bottom: 10px;
      }

      .brand {
        font-size: 18px;
      }
    }

    .num {
      position: absolute;
      right: 0px;
      bottom: 10px;
      font-size: 12px;
      text-align: right;
      display: flex;
      flex-direction: column;

      &>span {
        margin-right: 20px;
      }

      .red {
        font-size: 16px;
        color: #ee0000;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  .statistics {
    margin-top: 20px;

    .cros {
      text-decoration: line-through;
    }

    &>div {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
  }
}

.btnBox {
  width: 300px;
  text-align: center;
  margin: 30px auto;
}
</style>
